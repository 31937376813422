
/* #CSS CREATED FOR EVENTS PAGE but can be used for other pages as well */

#events{
  background-color: #f7f7f9;
  
  
}

.cta {
  width: 80%;
  display: flex;
  flex-direction: column;
   
  justify-content: center;

}
.cta div {
  width: 100%;
  display: inline;
}
 


#aboutus{
  background-color: #f7f7f9;
  overflow: hidden;
  
}

h1{
  width: 100%;
  font-size:130%;
  margin:0;
  padding:22px 0px 20px 0;
  background-color:#000;
  color:#fff;
  text-align: center;
}

section{
  overflow:hidden;
  background-color:#f7f7f9;
  
}

content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  
}
content .item{
  margin:20px;
  max-width:300px;
  background-color:#ffffff;
  
}

content .item .image{
  width: 100%;
  height:280px;
  object-fit: cover;
  
}

content .item h3{
  text-align: center;
  text-transform:uppercase;
  font-size: 110%;
  margin: 5px;
  
}

content .item p{
  text-align: center;
  font-size: 90%;
  padding:0 20px;
  
}


content .item a{
  display: block;
  text-align: center;
  font-size: 80%;
  padding:5px 20px 20px 20px;
  color:#ae894d;
  text-transform: uppercase;
  
}

/* #ABOUT US SECTION */

#wrapper{
  display: flex;
  overflow: hidden;
  background-color: #fff;
  
  
}

#leftsection,#rightsection{
  background-color: #fff;
  width: 50%;
  max-width: 800px;
  
}

#leftsection{
  margin-top: 40px;
  padding: 2em 6em ;
 
}



@media screen and (max-width: 800px) {
  #wrapper{
    flex-direction: column;
    flex-direction: column-reverse;
    margin: 0;
    padding: 0;
    
  }

  #leftsection,#rightsection{
    width: 100%;

  }

  #leftsection{
    align-items: flex-end;
    margin-top: 10px;
  }
  }


  /* #Contact us page */

  Contactus{
    display: flex;
    flex-direction: row;
    background-color:#1b1b1b;
  }

  #contactus button{
    margin: 10px auto;
    background-color: #fbf3da;
    color:#000;
    display:flex;
    width:100%;
    padding:10px;
  }

  @media screen and (max-width: 800px) {
    contactus{
      flex-direction: column;
    }
  }
