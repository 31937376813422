body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  
}

 
html,body{
  width: 100%;
  height: 100%;
  background-color: black;
  }
